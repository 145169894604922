<template>
  <div class="spinner-container">
    <b-spinner
      style="width: 3rem; height: 3rem; margin: 0 auto; display: block"
      variant="primary"
    />
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import axios from "@axios";

export default {
  components: {
    BSpinner,
  },
  mounted() {
    const { hash } = this.$route.query;
    this.handleNewRoute(hash);
  },
  methods: {
    async handleNewRoute(hash) {
      axios
        .put(`${process.env.VUE_APP_API}/verification/email/${hash}`)
        .then((response) => {
          console.log("response", response);
          console.log("response.data.url", response.data.data.url);
          this.$store.dispatch("auth/logout", {}).then(() => {
            localStorage.clear();
            window.location.href = response.data.data.url;
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-container {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
